//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Vue from "vue";
import MixinTeamOptions from "@/mixins/teamOptions";
import MixinWebrtc from "@/mixins/webrtcNew";
import Request from "@/webrtc/server/internal/Request";
import { RecordRTCPromisesHandler } from "recordrtc/RecordRTC";
import formatTime from "./format-time";

export default {
  name: "LiveScreen",
  components: {
    Header: () => import("@/components/Header"),
    selMember: () => import("@/components/Selectors/MemberSelectCopy"),
  },
  mixins: [MixinTeamOptions, MixinWebrtc],
  data() {
    return {
      functionName: "实时画面",
      NeedDataChannel: true,
      teamOptions: [], //团队选择器
      teamValue: null, //选择的团队
      selMem: [],
      allowAudio: false,
      screenRecording: false,
      recorder: null,
      stream: new MediaStream(),
      videoPath: "实时画面",
      loading: false,
      videos: [],
      startTime: null,
      endTime: null,
      drawer: false,
      screenRecordLoading: false,
      pageInfo: {
        current: 1,
        size: 10,
        total: 0,
        sizes: [5, 10, 50],
      },
      videoPlayTime: 0,
      videoPlayTimeId: null,
      webrtcState: "closed",
    };
  },
  computed: {
    videoPlayTimeFormated() {
      if (!this.videoPlayTime) {
        return formatTime(0, 600);
      }
      return formatTime(this.videoPlayTime, 600);
    },
    webrtcConnected() {
      return this.webrtcState == "open";
    },
    webrtcConnecting() {
      return this.webrtcState == "connecting";
    },
  },
  methods: {
    handleAllowAudioChange(val) {
      if (val) {
        var functions = this.Functions + ",Audio";
        this.changeWebrtcDataType(functions);
      } else {
        this.changeWebrtcDataType(this.Functions);
      }
    },
    async sendWebrtcRequestNoIdentity(request) {
      if (this.webrtcState != "open") {
        this.webrtcState = "connecting";
      }
      var identity = this.selMem[0].Identity;
      try {
        await this.Call(identity);
      } catch (error) {
        this.webrtcState = "closed";
        throw error;
      }
      var b = this.SendRequest(null, identity, request);
      if (!b) {
        this.webrtcState = "closed";
        throw "发送请求失败";
      }
    },
    handleGetData() {
      this.viewLive();
    },
    /**
     * 选择成员
     */
    getSelMember(arr) {
      this.selMem = arr;
      if (this.webrtc) {
        this.webrtc.dispose();
      }
    },
    /**
     * 团队切换
     */
    handleTeamChange() {
      this.selMem = [];
    },
    WebrtcOnConnectionStateChange(room, from, state) {
      this.webrtcState = state;
      if (state == "closed") {
        this.stopPlayerTime();
        if (this.$refs.video) {
          this.$refs.video.srcObject = null;
        }
        this.stream = new MediaStream();
      }
    },
    WebrtcOnRemoteTrack(peerConnection, event) {
      this.stream.addTrack(event.track);
      this.$nextTick(() => {
        this.$refs.video.srcObject = this.stream;
      });
    },
    viewLive() {
      this.videoPath = "实时画面";
      this.viewScreen(true);
    },
    viewRecord(path) {
      this.videoPath = path;
      this.viewScreen(false);
    },
    async viewScreen(closeAll) {
      var self = this;
      if (!this.teamValue) {
        this.$message({
          message: "请先选择团队",
          type: "warning",
        });
        return;
      }
      if (this.selMem.length == 0) {
        this.$message({
          message: "请先选择成员",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      if (closeAll) {
        this.HangUpAll();
      }
      if (this.webrtcState != "open") {
        this.webrtcState = "connecting";
      }
      var identity = this.selMem[0].Identity;
      try {
        await this.Call(identity);
      } catch (error) {
        self.$message({
          type: "error",
          message: error,
        });
        this.webrtcState = "closed";
        this.loading = false;
        throw error;
      }
      self.playVideo();
      if (closeAll) {
        self.getVideoList();
      }
    },
    stopScreen() {
      this.HangUpAll();
    },
    startRecord() {
      this.recorder = new RecordRTCPromisesHandler(this.stream, {
        type: "video",
        mimeType: "video/mp4",
      });
      this.recorder.startRecording();
      this.screenRecording = true;
    },
    async stopRecord() {
      await this.recorder.stopRecording();
      this.recorder.recordRTC.save("screen.mp4");
      this.screenRecording = false;
    },
    randomNum(minNum, maxNum) {
      switch (arguments.length) {
        case 1:
          return parseInt(Math.random() * minNum + 1, 10);
        case 2:
          return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10);
        default:
          return 0;
      }
    },
    handleSizeChange(val) {
      this.pageInfo.size = val;
      this.getVideoList();
    },
    handleCurrentChange(val) {
      this.pageInfo.current = val;
      this.getVideoList();
    },
    getVideoList() {
      var self = this;
      var user = self.selMem[0];
      if (!user) {
        return;
      }
      self.screenRecordLoading = true;
      this.videos = [];
      var data = {
        startTime: this.startTime,
        endTime: this.endTime,
        teamId: self.teamValue,
        userId: user.UsId,
        page: this.pageInfo.current,
        limit: this.pageInfo.size,
      };
      var request = new Request();
      request.Url = "/Video/ScreenRecordList";
      request.ContentType = "Json";
      const token = this.$xStorage.getItem("token");
      request.Headers.Authorization = token;
      request.Body = JSON.stringify(data);
      request.OnError = function (error) {
        self.$message({
          type: "error",
          message: error,
        });
        self.screenRecordLoading = false;
      };
      request.OnResponse = function (response) {
        let result = response.Result;
        if (!result.State) {
          self.$message({
            type: "error",
            message: result.Message,
          });
        } else {
          self.videos = result.Data.Datas;
          self.pageInfo.total = result.Data.Total;
        }
        self.screenRecordLoading = false;
      };
      this.sendWebrtcRequestNoIdentity(request).catch((error) => {
        this.$message({
          type: "error",
          message: error,
        });
        this.screenRecordLoading = false;
      });
    },
    startPlayerTime() {
      this.stopPlayerTime();
      this.videoPlayTimeId = setInterval(() => {
        this.videoPlayTime++;
      }, 1000);
    },
    stopPlayerTime() {
      if (this.videoPlayTimeId) {
        clearInterval(this.videoPlayTimeId);
      }
      this.videoPlayTime = 0;
    },
    playVideo() {
      var self = this;
      self.loading = true;
      var data = {
        videoPath: this.videoPath,
      };
      var request = new Request();
      request.Url = "/Video/PlayVideo";
      request.ContentType = "Json";
      const token = this.$xStorage.getItem("token");
      request.Headers.Authorization = token;
      request.Body = JSON.stringify(data);
      request.OnError = function (error) {
        self.$message({
          type: "error",
          message: error,
        });
        self.loading = false;
      };
      request.OnResponse = function (response) {
        let result = response.Result;
        if (!result.State) {
          self.$message({
            type: "error",
            message: result.Message,
          });
        } else {
          self.startPlayerTime();
        }
        self.loading = false;
      };
      this.sendWebrtcRequestNoIdentity(request).catch((error) => {
        this.$message({
          type: "error",
          message: error,
        });
        this.loading = false;
      });
    },
  },
  beforeDestroy() {
    this.HangUpAll();
  },
};
